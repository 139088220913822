



























































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import {Address, AppConfig, FlowInputField, PlaceAutocompleteItem, Pos} from '@/lib/kepler/interfaces'
import CountryList from '@/lib/kepler/countries'
import {VAutocomplete, VContainer, VLayout} from 'vuetify/lib'
import Utils from '@/utils'
import TextField from '@/components/proxy/Inputs/TextField.vue'
import AutoComplete from '@/components/proxy/Inputs/AutoComplete.vue'
import Locate, {Position} from '@/lib/location'
import {FlowOutputsState} from '@/store/modules/flowOutputs'
import sdk from '@/lib/kepler/sdk'
import CountrySelector from '@/components/CountrySelector.vue'
import Validations from '@/lib/Validations'

@Component({
  components: {
    CountrySelector,
    AutoComplete,
    TextField,
    VAutocomplete,
    VContainer,
    VLayout,
  },
  name: 'RegistrationAddressComponent',
})
export default class RegistrationAddressComponent extends Vue {
  @State('flowOutputs') public flowOutputs!: FlowOutputsState

  @Prop({
    type: String,
  }) public context!: string
  @Prop({}) public readonly options!: string | string[] | Record<string, any>

  @State((state) => state.profile.userPosition) public userPosition!: Pos | null
  @State((state) => state.configuration.appConfig) public appConfig!: AppConfig

  @Action('setUserPosition') public setUserPosition!: (p: Position) => void

  @Prop() public field!: FlowInputField

  public required: boolean = false
  public valid: boolean = false
  public loading: boolean = false
  public errorMessages: string[] = []
  public countries: any = CountryList

  public autoCompleteInput: string = ''
  public autoCompleteOutput: PlaceAutocompleteItem[] = []

  public address: PlaceAutocompleteItem | null = null
  public attribution: string | null = null

  protected rules = Validations.rules

  public get selfContained() {
    return this.getOption('self_contained')
  }

  public get geolocate() {
    return this.getOption('geolocate')
  }

  public get currentFlow() {
    return this.flowOutputs[this.context]
  }

  public get addressOutput(): Partial<Address> {
    return this.currentFlow?.address || {}
  }

  public set addressOutput(payload: Partial<Address>) {
    this.$set(this.currentFlow, 'address', payload)
  }

  public get hasAddressOutput() {
    return !!Object.keys(this.addressOutput).length
  }

  public getPosition(): Promise<Pos> {
    return new Promise((resolve, reject) => {
      const [lat, lng] = [this.appConfig.default_latitude, this.appConfig.default_longitude]
      if (this.userPosition) {
        resolve(this.userPosition)
      } else if (lat && lng) {
        resolve({lat, lng})
      } else {
        Locate.please_locate((p) => {
          if (p) {
            this.setUserPosition(p)
            resolve({lat: p.lat, lng: p.lng})
          } else {
            reject()
          }
        })
      }
    })
  }

  public resetAddress() {
    (this.$refs.addressAutocomplete as any).clearableCallback()
    this.addressOutput = {}
  }

  public reverseGeo() {
    this.resetAddress()
    this.loading = true
    this.getPosition().then((p) => {
      sdk.map.reverseGeocode({latitude: p.lat, longitude: p.lng}).then(({data}) => {
        this.autoCompleteInput = data.result
        this.search()
      })
    }).finally(() => {
      this.loading = false
    })
  }

  @Watch('autoCompleteInput')
  public search() {
    if (this.autoCompleteInput?.length > 5) {
      this.loading = true
      this.getPosition().then((position) => {
        sdk.map.placeAutocomplete({
          input: this.autoCompleteInput,
          ...position,
        }).then(({data}) => {
          this.autoCompleteOutput = data.places
          this.attribution = data.attribution || null
        }).catch(() => {
          this.autoCompleteOutput = []
        }).finally(() => {
          this.loading = false
        })
      }).catch(() => {
        this.autoCompleteOutput = []
        this.loading = false
      })
    } else {
      this.autoCompleteOutput = []
    }
  }

  @Watch('address', {deep: true})
  public async onAddressSelected(addr: PlaceAutocompleteItem) {
    if (addr?.place) {
      this.setAddressComponentProps(addr.place)
    } else if (!!addr) {
      sdk.map.placeDetail(addr.place_id).then(({data}) => {
        this.setAddressComponentProps(data)
      })
    }
  }

  public setAddressComponentProps(r: Partial<Address>) {
    const strNumbErrIdx = this.errorMessages.indexOf(this.$t('registration.address.street_number_error'))

    const addressOut = Utils.getProp(this.currentFlow, this.field.name.split('.'))
    const setProp = (key: keyof Address, value: string) => {
      Utils.setProp(addressOut as Record<string, any>, [key], value)
    }

    for (const key of Object.keys(r) as Array<keyof typeof r>) {
      if (key === 'street') { // LET THE JANK BEGIN
        if (r.street_number) {
          if (r.country?.toLowerCase() !== 'it') {
            setProp(key, `${r.street_number} ${r.street}`)
          } else {
            setProp(key, `${r.street} ${r.street_number}`)
          }
          if (strNumbErrIdx >= 0) {
            this.errorMessages.splice(strNumbErrIdx, 1)
          }
          continue
        } else if (strNumbErrIdx < 0) {
          this.errorMessages.push(this.$t('registration.address.street_number_error'))
        }
      }
      setProp(key, r[key] ?? '')
    }
  }

  protected getOption(opt: string) {
    switch (typeof this.options) {
      case 'string':
        return this.options?.includes(opt)
      case 'object':
        if (Array.isArray(this.options)) {
          return this.options?.includes(opt)
        } else if (this.options !== null && opt in this.options) {
          return this.options.self_contained
        } else {
          return false
        }
      default:
        return false
    }
  }

  protected created() {
    this.loading = true
    if (!this.currentFlow) {
      this.$set(this.flowOutputs, this.context, {})
    }
    if (!Utils.getProp(this.currentFlow, this.field.name.split('.'))) {
      Utils.setProp(this.currentFlow, this.field.name.split('.'), {})
    }
    this.loading = false
  }
}
