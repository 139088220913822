














































import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import {ReportingsState} from '@/store/modules/reportings'

import Validations from '@/lib/Validations'
import Locate, {Position} from '@/lib/location'
import ConfirmDialogCallback from '../ConfirmDialogCallback.vue'
import Utils from '@/utils'

import FullListHistory from '@/views/Booking/FullListHistory.vue'
import sdk from '@/lib/kepler/sdk'
import {
  AppConfig,
  Attachment,
  DamagePosition,
  ReportRequest,
  ReportType,
  ReservationResponse,
  Vehicle,
  VehicleSlot,
} from '@/lib/kepler/interfaces'

declare let device: any

@Component({
  components: {
    CameraButtonCustom: Utils.loadComponent('uploader/CameraButtonCustom'),
    AttachmentsLoader: Utils.loadComponent('AttachmentsLoader'),
    VehicleQuickInfo: Utils.loadComponent('entities/vehicle/VehicleQuickInfo'),
    VehicleDamage: Utils.loadComponent('entities/vehicle/VehicleDamage'),
    CameraButton: Utils.loadComponent('uploader/CameraButton'),
    CloseButton: Utils.loadComponent('CloseButton'),
    SelectTag: Utils.loadComponent('proxy/Inputs/SelectTag'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    Container: Utils.loadComponent('proxy/Container'),
    TextArea: Utils.loadComponent('proxy/Inputs/TextArea'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Plate: Utils.loadComponent('Plate'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Img: Utils.loadComponent('proxy/Image'),
    Btn: Utils.loadComponent('proxy/Btn'),
    FullListHistory,
  },
  name: 'VehicleAddReport',
})
export default class VehicleAddReport extends Vue {
  @State('reportings') public reportState!: ReportingsState
  @State((state) => state.configuration.appConfig) public appConfig!: AppConfig

  @Prop() public vehicleSlot?: VehicleSlot
  @Prop() public reportType!: string | null
  @Prop() public description!: string
  @Prop() public reservationId!: string | null
  @Prop() public preFilledReport?: ReportRequest
  @Prop({type: Boolean, default: false}) public pictureReport?: boolean
  @Prop({type: Boolean, default: true}) public selectable?: boolean
  @Prop({type: Function, required: false}) public callback?: () => void

  @Action('sendReport') public sendReport!: any
  @Action('reportTypes') public fetchReportTypes!: any

  protected vehicle: Vehicle | null = null
  protected rules: any = Validations.rules
  protected selectedReport: ReportType | null = null
  protected loading: boolean = false
  protected streetName: string = ''
  protected streetNumber: string = ''
  protected error: any[] = []
  protected online: boolean = navigator.onLine
  protected uploadedAttachments: Attachment[] = []

  protected reportRequest: ReportRequest = {
    vehicle_id: this.vehicle?.id || '',
    reservation_id: this.reservationId || '',
    reporting_type_id: null,
    description: '',
    location: null,
    position: {latitude: 0, longitude: 0},
    attachments: [],
    damage_position_raw: [],
  }

  protected get confirmEnabled() {
    const hasReportingType = !!this.reportRequest.reporting_type_id

    const needsDescription = !this.pictureReport
    const hasDescription = !!this.reportRequest.description

    const needsPicture = !!this.pictureReport
    const hasPicture = this.uploadedAttachments.length

    const needsLocation = !!this.selectedReport?.position_required
    const hasLocation = !!this.reportRequest.location

    const needsParkingReport = this.parkingReport
    const hasParkingReport = this.streetName !== '' && this.streetNumber !== ''

    return [
      hasReportingType,
      needsDescription ? hasDescription : true,
      needsPicture ? hasPicture : true,
      needsLocation ? hasLocation : true,
      needsParkingReport ? hasParkingReport : true,
    ].every((p) => p)
  }

  protected get damageReport() {
    return this.reportRequest.reporting_type_id === this.appConfig.damage_report_id
  }

  protected get parkingReport() {
    return this.reportRequest.reporting_type_id === this.appConfig.parking_report_id
  }

  protected get hasPicture() {
    return !!(this.selectedReport?.attachment_required || this.pictureReport)
  }

  protected created() {
    this.vehicle = this.$attrs.vehicle ? this.$attrs.vehicle as any : this.vehicleSlot?.vehicle || null

    if (this.preFilledReport) {
      this.$set(this, 'reportRequest', this.preFilledReport)
    } else {
      this.fetchReportTypes()
    }
    if (this.reportType) {
      const selectedReportType = this.reportState.reportTypes.find((r) => r.id === this.reportType)
      if (selectedReportType) {
        this.selectedReport = selectedReportType
      }
    }
  }

  protected mounted() {
    this.reportRequest.reporting_type_id = this.reportType
    this.reportRequest.description = this.description
    Locate.locate((position: Position) => {
        sdk.map.reverseGeocode({latitude: position.lat, longitude: position.lng}).then((r) => {
          const p = r.data
          this.reportRequest.position!.latitude = p.latitude
          this.reportRequest.position!.longitude = p.longitude
          this.reportRequest.location = r.data.result
        })
      }, {
        lat: this.appConfig.default_latitude,
        lng: this.appConfig.default_longitude, acc: 0,
      }, () => {
        return
      },
    )
  }

  protected setReportType(type: ReportType) {
    this.selectedReport = type
    this.reportRequest.reporting_type_id = type.id

    if (this.parkingReport) {
      this.reportRequest.description = this.$t('vehicle.report.park_occupied')
    } else {
      this.reportRequest.description = ''
    }
  }

  protected gotAttachment(r: Attachment) {
    this.uploadedAttachments.push(r)
    this.reportRequest.attachments.push(r.token)
  }

  protected removeAttachment(attachment: Attachment) {
    this.$dialog.open(ConfirmDialogCallback, {
      props: {
        imageState: attachment.url,
        code: '',
        title: 'delete attachment?',
        subtitle: '',
        confirmText: this.$t('action.confirm'),
        confirmCallback: () => {
          const atts = this.reportRequest.attachments
          const i = atts.findIndex((t) => t === attachment.token)
          if (i >= 0) {
            this.reportRequest.attachments.splice(i, 1)
          }
          this.$set(this, 'uploadedAttachments', this.uploadedAttachments.filter((a) => a.token !== attachment.token))
        },
        cancelCallback: () => {
          //
        },
      },
    })
  }

  protected removed(index: number) {
    this.reportRequest.attachments.splice(index, 1)
  }

  protected pointUpdated(points: DamagePosition[]) {
    this.reportRequest.damage_position_raw = points
  }

  protected selectReservation(r: ReservationResponse) {
    this.$popup.open(VehicleAddReport, {
      props: {
        vehicleSlot: r.vehicle_slot,
        vehicle: r.vehicle_slot.vehicle,
      }, title: this.$t('vehicle.report.diary_report'),
    })
  }

  protected send() {
    if (this.vehicle) {
      this.reportRequest.vehicle_id = this.vehicle.id
      this.loading = true
      if (this.parkingReport) {
        this.reportRequest.description = this.$t('vehicle.report.park_occupied_full', {
          streetName: this.streetName,
          streetNumber: this.streetNumber,
        })
      } else if (this.pictureReport) {
        this.reportRequest.description = '-'
      }
      this.sendReport(this.reportRequest).then(() => {
        this.confirmSentReport()
      }).finally(() => {
        this.loading = false
      })
    }
  }

  protected confirmSentReport() {
    this.$dialog.open(ConfirmDialogCallback, {
      props: {
        imageState: 'success.svg',
        code: '',
        title: this.$t('vehicle.report.report_added'),
        subtitle: '',
        confirmText: this.$t('action.close'),
        singleAction: true,
        showCloseButton: false,
        confirmCallback: () => {
          this.$dialog.close()
          this.$popup.close()
          if (this.callback) {
            this.callback()
          }
        },
      }, hideTopbar: false,
    })
  }
}
