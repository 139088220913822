





























































































































































import {Component, Vue} from 'vue-property-decorator'
import {State} from 'vuex-class'

import {AppConfig, BookingMode, SubscriptionPlan, Survey, VehicleType} from '@/lib/kepler/interfaces'

import {EventBus} from '@/main'
import Utils from '@/utils'

import Validations from '@/lib/Validations'
import ServiceMesh from '@/lib/serviceMesh'
import fakeData from '@/lib/fakeData'

import AddDriver from '@/components/AddDriver.vue'
import AddClient from '@/components/AddClient.vue'
import QuickExtendDialog from '@/components/QuickExtendDialog.vue'

import AddCardDialog from '@/views/AddCardDialog.vue'
import AlreadyHasAccount from '@/views/AlreadyHasAccount.vue'
import ForceTerminateDialog from '@/views/Booking/ForceTerminateDialog.vue'
import VehicleConfirmBooking from '@/views/Vehicle/VehicleConfirmBooking.vue'
import AddCredit from '@/views/AddCredit.vue'
import ForceTerminateChecklist from '@/views/Booking/ForceTerminateChecklist.vue'
import ChecklistDialogCallback from '@/views/ChecklistDialogCallback.vue'
import FuelPinDialog from '@/views/FuelPinDialog.vue'
import TerminateMode from '@/views/TerminateMode.vue'
import BookingEdit from '@/views/Booking/BookingEdit.vue'
import VehicleAddReportDialog from '@/views/Vehicle/VehicleAddReportDialog.vue'
import ChangePasswordDialog from '@/views/ChangePasswordDialog.vue'
import ErrorView from '@/views/Error.vue'
import MultipleDialogCallback from '@/views/MultipleDialogCallback.vue'
import LoginDialog from '@/views/LoginDialog.vue'
import VehicleBookingDateTimeSelect from '@/views/Vehicle/VehicleBookingDateTimeSelect.vue'
import ConfirmDialog from '@/views/ConfirmDialog.vue'
import ChangeLangDialog from '@/views/ChangeLangDialog.vue'
import TextareaDialog from '@/views/TextareaDialog.vue'
import PhoneVerificationView from '@/views/PhoneVerification.vue'
import VehicleBookingAddMemoDialogView from '@/views/Vehicle/VehicleBookingAddMemoDialog.vue'
import PinRequestCallback from '@/views/Vehicle/PinRequestCallback.vue'
import LoaderDialog from '@/views/LoaderDialog.vue'
import ConfirmTerminateDialog from '@/views/Booking/ConfirmTerminateDialog.vue'
import SurveyDialog from '@/views/Booking/Survey.vue'
import PlanDetailDialog from '@/views/PlanDetailDialog.vue'
import ReservationOTPDialog from '@/views/ReservationOTPDialog.vue'
import TopBarPopUp from '@/components/TopBarPopUp.vue'

@Component({
  components:
    {
      AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
      Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
      Container: Utils.loadComponent('proxy/Container'),
      Layout: Utils.loadComponent('proxy/Layout'),
      Button: Utils.loadComponent('Button'),
      SelectTag: Utils.loadComponent('proxy/Inputs/SelectTag'),
      PhoneWithPrefix: Utils.loadComponent('PhoneWithPrefix'),
    },
  name: 'DevDialogs',
})

export default class DevDialogs extends Vue {
  @State((state) => state.configuration.appConfig) public appConfig!: AppConfig
  @State((state) => state.subscriptions.plans) public plans!: SubscriptionPlan[]

  public rules = Validations.rules
  public phoneNumber: string = ''
  public bookingConfirmSelected = {
    bookingMode: null,
    vehicleType: null,
    action: null,
  }
  public bookingConfirmData = {
    bookingModes: ['RT', 'FF', 'OWFF'],
    vehicleTypes: ['car'],
    actions: ['confirm'],
  }
  private fakeVehicleSlot = fakeData.vehicleSlot
  private fakeBookRequest = fakeData.bookRequest
  private fakeReservation = fakeData.reservation
  private checkList = [
    {
      id: 'item1',
      title: 'ignition is off',
      text: 'I\'ve checked the control panel and there are no blinking lights.',
      icon: 'mdi-key-variant',
    },
    {
      id: 'item2',
      title: 'combobulator is off',
      description: 'I don\'t know what it is but I\'ve checked very carefully.',
      icon: 'mdi-lock-outline',
    },
    {
      id: 'item3',
      title: 'reaction control system is nominal',
      description: 'Also, I\'ve degaussed the flywheels thoroughly.',
      icon: 'mdi-flash-off',
    },
    {
      id: 'item4',
      title: 'I\'ve parked decently',
      description: 'I\'ve checked with mission control the stability of the orbit.',
      icon: 'mdi-parking',
    },
  ]

  public openSingleAction() {
    this.$dialog.open(ConfirmDialog, {
      props: {
        imageState: 'logo.png',
        confirmText: 'confirmText',
        singleAction: true,
        emitConfirm: false,
      },
    })
  }

  public openStuff(repeatTimes: number = 5, intervalBetween: number = 250) {
    const repeatXI = (callback: () => any, interval: number, repeats: number) => {
      const trigger = () => {
        callback()
        --repeats
        if (repeats === 0) {
          clearInterval(timer)
        }
      }
      const timer = setInterval(trigger, interval)
      trigger()
    }

    let iteration = 1

    const dialog = () => {
      this.$dialog.open(ConfirmDialog, {
        props: {
          imageState: 'logo.png',
          confirmText: this.$t('common.ok'),
          code: `repeated ${iteration} times of ${repeatTimes}`,
          title: '',
          subtitle: '',
          singleAction: true,
          emitConfirm: false,
        },
      })
      iteration++
    }
    repeatXI(dialog, intervalBetween, repeatTimes)
  }

  public openConfirmBooking(bookingMode: BookingMode, vehicleType: VehicleType, action: string = 'confirm') {
    const vehicleSlot = this.fakeVehicleSlot
    this.fakeVehicleSlot.reservation_type = bookingMode
    this.fakeVehicleSlot.vehicle.category.type = vehicleType
    this.$popup.open(VehicleConfirmBooking, {props: {vehicleSlot, action}, hideTopbar: true})
  }

  public mounted() {
    EventBus.$on('dialogConfirm', (payload: any) => {
      alert('confirmed, payload: \n' + payload)
    })
    this.bookingConfirmData.bookingModes = ServiceMesh.bookingModes
    this.bookingConfirmData.vehicleTypes = ServiceMesh.vehicleTypes
  }

  public beforeDestroy() {
    EventBus.$off('dialogConfirm')
  }

  // tslint:disable: cyclomatic-complexity
  public dialog(type: string) {
    switch (type) {
      case 'confirm':
        this.$dialog.open(ConfirmDialog, {
          props: {
            imageState: 'success.svg',
          },
        })
        break
      case 'multiple':
        this.$dialog.open(MultipleDialogCallback, {
          props: {
            title: 'pick your poison',
            subtitle: 'but be careful what you wish for',
            options: [{
              text: 'i want eklfdj',
              value: 'eklfdj',
            }, {
              text: 'i\'d fancy dgsdgg',
              color: 'purple darken-4',
              value: 'dgsdgg',
            }, {
              text: 'i require sdgdfh',
              color: 'error',
              value: 'sdgdfh',
            }],
            callback: (option: string) => {
              alert(`you got ${option}`)
            },
          },
        })
        break
      case 'checklist':
        this.$dialog.open(ChecklistDialogCallback, {
          props: {
            allRequired: false,
            title: 'I HEREBY DECLARE',
            subtitle: 'take a deep breath...',
            confirmColor: 'accent',
            options: [
              {
                title: 'ignition is off',
                text: 'I\'ve checked the control panel and there are no blinking lights.',
                icon: 'mdi-key-variant',
                value: 'ignition',
              },
              {
                title: 'combobulator is off',
                text: 'I don\'t know what it is but I\'ve checked very carefully.',
                icon: 'mdi-lock-outline',
                value: 'combobulator',
              },
              {
                title: 'reaction control system is nominal',
                text: 'Also, I\'ve degaussed the flywheels thoroughly.',
                icon: 'mdi-flash-off',
                value: 'RCS',
              },
              {
                title: 'I\'ve parked decently',
                text: 'I\'ve checked with mission control the stability of the orbit.',
                icon: 'mdi-parking',
                value: 'parking',
              },
            ],
            callback: (options: string[]) => {
              alert(`you selected ${options.join(', ')}.`)
            },
          },
        })
        break
      case 'survey':
        const survey: Survey[] = [
          {
            _has_rating: false,
            _has_report: false,
            mandatory: true,
            needs_report: null,
            survey: 'THE_QUESTION',
            text: 'yes or no?',
            text_no: 'what?',
            text_yes: 'why?',
            type: 'boolean',
          }, {
            _has_rating: false,
            _has_report: false,
            mandatory: true,
            needs_report: this.appConfig.damage_report_id,
            survey: 'STARS',
            text: 'by the way, how many stars do you like?',
            text_no: '',
            text_yes: '',
            type: 'rating',
          }, {
            _has_rating: false,
            _has_report: false,
            mandatory: false,
            needs_report: this.appConfig.damage_report_id,
            survey: 'DAMAGE',
            text: 'don\'t tap this',
            text_no: '',
            text_yes: '',
            type: 'report',
          }]
        let surveyDialogId: string
        this.$dialog.open(SurveyDialog, {
          props: {
            survey,
            reservationNumber: this.fakeReservation.number,
            reservationId: this.fakeReservation.id,
            vehicleSlot: this.fakeReservation.vehicle_slot,
            confirmCallback: () => {
              this.$log('survey confirmed', 1)
              this.$dialog.close(surveyDialogId)
            },
            cancelCallback: () => {
              this.$log('survey cancelled', 1)
            },
          },
        }).then((id) => surveyDialogId = id)
        break
      case 'checklistSimple':
        this.$dialog.open(ChecklistDialogCallback, {
          props: {
            allRequired: true,
            title: 'Parking check',
            options: [
              {
                text: 'I falsely affirm that I\'ve parked decently.',
                value: 'parking',
              },
            ],
            callback: () => {
              alert(`you liar`)
            },
          },
        })
        break
      case 'error':
        this.$dialog.open(ErrorView, {
          props: {
            code: 'code 123',
            title: 'title',
            subtitle: 'error subtitle',
            singleAction: true,
          },
        })
        break
      case 'ChangeLang':
        this.$dialog.open(ChangeLangDialog, {})
        break
      case 'phoneVerification':
        this.$dialog.open(PhoneVerificationView, {
          props: {
            mobileNumber: this.phoneNumber,
            isDialog: true,
          },
        })
        break
      case 'pinRequest':
        this.$dialog.open(PinRequestCallback, {
            props: {
              reservation: this.fakeReservation,
              showCloseButton: true,
              confirmCallback: () => {
                this.$log('pinRequest dialog confirmed', 1)
              },
              cancelCallback: () => {
                this.$log('pinRequest dialog cancelled', 1)
              },
            },
          },
        )
        break
      case 'fuelPin':
        this.$dialog.open(FuelPinDialog, {
          props: {
            imageState: 'success.svg',
            confirmText: this.$t('action.close'),
            pin: 12345,
            odometer: 12345,
            callback: () => {
              alert('callback')
            },
            data: this.$t('vehicle.refuel.necessary'),
            singleAction: true,
            emitConfirm: false,
          },
        })
        break
      case 'memo':
        this.$dialog.open(VehicleBookingAddMemoDialogView, {
          props: {
            vehicleSlot: this.fakeVehicleSlot,
            bookRequest: this.fakeBookRequest,
            confirmCallback: () => {
              alert('confirmed')
            },
            cancelCallback: () => {
              alert('cancelled')
            },
          },
        })
        break
      case 'addCredit':
        this.$dialog.open(AddCredit, {props: {confirmText: '*Buy*'}})
        break
      case 'book':
        this.$dialog.open(VehicleBookingDateTimeSelect, {
          props: {
            vehicleSlot: this.fakeVehicleSlot,
            bookRequest: this.fakeBookRequest,
          },
        })
        break
      case 'terminate':
        const checkBoxLabel = this.$t('booking.terminate_mode.accept_undesirable')
        this.$dialog.open(ForceTerminateDialog, {props: {checkBoxLabel, terminateMessages: ['', '1000']}})
        break
      case 'bookingEdit':
        this.$dialog.open(BookingEdit, {
          props: {
            reservation: this.fakeReservation,
            confirmCallback: () => {
              alert('confirm')
            },
            cancelCallback: () => {
              alert('cancel')
            },
          },
        })
        break
      case 'bookingExtend':
        this.$dialog.open(QuickExtendDialog, {
          props: {
            reservation: this.fakeReservation,
            availability: [], // TODO: fake this
            onConfirm: () => {
              this.$log('fake extend done', 1)
            },
          },
        })
        break
      case 'alreadyHasAccount':
        const error = {
          result: 'Hai già un account',
          result_code: 'exceptions.system.already-has-account-exception',
          messages: ['Recupera la tua password per accedere'],
        }
        this.$dialog.open(AlreadyHasAccount, {props: {err: error}})
        break
      case 'forceTerminateChecklist':
        this.$dialog.open(ForceTerminateChecklist, {
          props: {
            list: this.checkList,
            title: 'I\'m pretty sure that...',
          },
        })
        break
      case 'loginDialog':
        this.$dialog.open(LoginDialog, {hideTopbar: true})
        break
      case 'addReportDialog':
        this.$dialog.open(VehicleAddReportDialog, {
          props: {
            vehicleSlot: this.fakeReservation.vehicle_slot,
            vehicle: this.fakeReservation.vehicle_slot.vehicle,
            reportType: null,
            reservationId: this.fakeReservation.id,
          },
        })
        break
      case 'changePasswordDialog':
        this.$dialog.open(ChangePasswordDialog, {})
        break
      case 'addCard':
        this.$dialog.open(AddCardDialog, {})
        break
      case 'plan':
        this.$dialog.open(PlanDetailDialog, {props: {plan: this.plans[0]}})
        break
      case 'terminateModePopup':
        const loader = false
        this.$popup.open(TerminateMode, {
          props: {
            reservation: this.fakeReservation,
            terminateCb: () => undefined,
            loader,
          },
          title: this.$t('booking.terminate_mode.title'),
        })
        break
      case 'terminateConfirm':
        this.$dialog.open(ConfirmTerminateDialog, {
          props: {
            response: {
              duration: 10000,
              distance: 100,
              time_cost: 1000,
              distance_cost: 1000,
            },
          },
        })
        break
      case 'addDriver':
        this.$dialog.open(AddDriver, {})
        break
      case 'addClient':
        this.$dialog.open(AddClient, {})
        break
      case 'textArea':
        this.$dialog.open(TextareaDialog, {
          props: {
            title: 'Text area',
            subtitle: 'to write some stuff',
            placeholder: 'jot something down',
            confirmText: 'and press this button',
            required: true,
            confirmCallback: (t: string) => {
              alert('you wrote: ' + t)
            },
          },
        })
        break
      case 'OTP':
        this.$dialog.open(ReservationOTPDialog, {
          props: {
            alreadyRequested: false,
            title: 'validator title',
            subtitle: 'validator subtitle',
            requestText: 'request otp',
            verifyText: 'validate otp',
            reservationNumber: this.fakeReservation.number,
            confirmCallback: () => {
              this.$log('OTP sent', 2)
            },
          },
        })
        break
      case 'loaderClose':
        this.$dialog.open(LoaderDialog, {
          props: {
            showCloseButton: true, closeCallback: () => {
              this.$log('you closed the loader, you monster', 2)
            },
          },
        })
        break
      case 'loaderButton':
        this.$dialog.open(LoaderDialog, {
          props: {
            buttonText: 'Button',
            buttonCallback: () => {
              this.$dialog.close()
              this.$dialog.open(ErrorView, {
                props: {
                  title: 'you buttoned the button',
                  subtitle: 'you freak',
                  singleAction: true,
                },
              })
            },
          },
        })
        break
      case 'loaderTimeout':
        this.$dialog.open(LoaderDialog, {
          props: {
            text: [
              'Hold tight for 5 seconds, guv.',
              'Just hold your horses for a quick 5 seconds.',
              'Give us a tick to sort things out, about 5 seconds should do it.',
              'Wait a mo, we\'ll have it sorted in 5 seconds. ',
              'Just keep calm and wait for 5 seconds, mate.',
              'Wait a jiffy for 5 seconds, we\'ll be with you shortly.',
              'Take a brief pause for 5 seconds, and we\'ll be right back.',
              'Just a short 5-second wait, we\'re almost there. ',
              'Stay with us for 5 seconds, we\'ll have it done in no time.',
              'Wait patiently for 5 seconds, we\'ll be ready in a jiff.',
              'Hang on for just 5 seconds, we got this!',
              'Wait 5 seconds, it\'s all good!',
              'Just 5 seconds, stay cool!',
              'Give us 5 seconds, we\'re on it!',
              'Hold up for 5 sec, don\'t trip!',
              'Chill for 5 sec, we\'re loading, yo!',
              'Stay put for 5 sec, we\'ll be lit!',
              'Wait 5 sec, we\'ll be back!',
              'Just 5 sec, we\'ll get it poppin\'!',
              'Hold your horses for 5 sec, we\'ll be fire!',
            ],
          },
        }).then((idx) => {
          setTimeout(() => {
            this.$log('loader closed after 5s', 1)
            this.$dialog.close(idx)
          }, 5000)
        })
        break
      case 'fullscreen':
        this.$popup.open(TopBarPopUp, {
          props: {
            langSelector: true,
          },
          fullscreen: true,
          hideTopbar: true,
          title: 'profile.edit.personal.title',
        })
    }
  }
}
