











































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {WalletState} from '@/store/modules/wallet'
import {RecordRequest, Wallet, WalletRecord} from '@/lib/kepler/interfaces'
import collect from 'collect.js'
import moment from 'moment'
import {TopbarButton} from '@/store/modules/topbar'

import Container from '../proxy/Container.vue'
import Layout from '../proxy/Layout.vue'
import GradientCard from '../GradientCard.vue'
import Accordion from '../proxy/Accordion/Accordion.vue'
import AccordionContent from '../proxy/Accordion/AccordionContent.vue'
import MonthList from '../MonthList.vue'
import CardButton from '../CardButton.vue'
import AddCredit from '../../views/AddCredit.vue'
import Button from '../Button.vue'
import CustomIcon from '../CustomIcon.vue'
import FitText from '@/components/FitText.vue'
import WalletRecordDetail from './WalletRecordDetail.vue'
import Sheet from '@/components/proxy/Sheet.vue'

@Component({
  name: 'OverviewWallet',
  components: {
    Sheet,
    WalletRecordDetail,
    FitText,
    CustomIcon,
    Button,
    Container,
    CardButton,
    Layout,
    Accordion,
    AccordionContent,
    GradientCard,
    MonthList,
    AddCredit,
  },
})
export default class OverviewWallet extends Vue {
  @State('wallet') public walletState!: WalletState
  @Getter('defaultWallet') public defaultWallet?: Wallet
  @Action('getWallets') public getWallets!: () => Promise<Wallet[]>
  @Action('getRecords') public getRecords!: (req: RecordRequest) => Promise<[WalletRecord]>
  @Action('setDateOverview') public setDate: any
  @Action('setActionButtons') public setActionButtons!: (buttons: TopbarButton[]) => void

  @Prop({
    default: 180,
    type: Number,
  }) private angle?: number
  @Prop({
    default: '#aaa',
    type: String,
  }) private color1?: string
  @Prop({
    default: '#999',
    type: String,
  }) private color2?: string
  @Prop({
    type: String,
  }) private customCSS?: string
  @Prop({}) private name?: string
  @Prop({}) private monthProp!: string
  @Prop({
    default: 'credit',
    type: String,
  }) private type!: string

  private selectedDate: string | null = null
  private month: string = moment().locale('en').format('YYYY-MM')
  private listLoading: boolean = false

  private get balance(): number {
    return this.defaultWallet?.[`${this.type}_balance`]
  }

  private get recordList() {
    this.listLoading = true
    const records = this.defaultWallet?.records
    const walletRecords = records?.[this.month]?.[this.type]
    if (walletRecords) {
      this.$emit('monthLoaded', this.month)
      this.listLoading = false
      return walletRecords
    } else {
      this.getRecords({id: this.wallet.id, account_type: this.type, month: this.month})
        .then(() => {
          if (this.defaultWallet?.records?.[this.month]?.[this.type]) {
            this.$emit('monthLoaded', this.month)
            return this.defaultWallet.records[this.month][this.type]
          }
        })
        .finally(() => {
          this.listLoading = false
        })
    }
  }

  private get wallet(): Wallet {
    return collect(this.walletState.wallets).firstWhere('default', true)
  }

  private pos(n: number) {
    return n > 0 ? 'success--text' : 'error--text'
  }

  private changedMonth(month: string) {
    this.month = month
    this.setDate(month)
  }

  private formatDate(date: string) {
    return moment(date).format('LL')
  }

  private openAddCredit() {
    this.$dialog.open(AddCredit, {
      props: {
        confirmText: `${this.$t('activities.book_and_shop.buy')}`,
      },
    })
  }

  private openRecordDetail(record: WalletRecord) {
    this.$popup.open(WalletRecordDetail, {props: {record}, title: 'Record'})
  }

  // private mounted() {
  //   const thisMonth = moment().format('YYYY-MM')
  //   this.getRecords({id: this.wallet.id, account_type: 'credit', month: thisMonth})
  //
  //   // this.getRecords({id: this.wallet.id, account_type: 'credit', month: thisMonth})
  //
  //   // if (this.walletState.dateOverview == null) {
  //   //   this.setDate(thisMonth)
  //   //   this.selectedDate = thisMonth
  //   // } else {
  //   //   this.selectedDate = this.walletState.dateOverview
  //   // }
  //   // if (this.walletState.wallets == null) {
  //   //   this.getWallets(thisMonth)
  //   // }
  //
  //   if (this.walletState.dateOverview == null) {
  //     this.setDate(thisMonth)
  //     this.selectedDate = thisMonth
  //   } else {
  //     this.selectedDate = this.walletState.dateOverview
  //   }
  //   if (this.walletState.wallets == null) {
  //     this.getRecords({id: this.wallet.id, account_type: 'credit', month: thisMonth})
  //   }
  //   console.log('mounted', this.wallet.records)
  // }
}
