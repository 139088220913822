



































import {Vue, Component} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {FieldConfigs, Invoice} from '@/lib/kepler/interfaces'
import moment from 'moment'
import InvoiceDetail from './InvoiceDetail.vue'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import Utils from '@/utils'
import Img from '@/components/proxy/Image.vue'

@Component({
  name: 'Invoices',
  components: {
    Img,
    IsLogged: Utils.loadComponent('IsLogged'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    GradientCard: Utils.loadComponent('GradientCard'),
    MonthList: Utils.loadComponent('MonthList'),
    CardButton: Utils.loadComponent('CardButton'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Button: Utils.loadComponent('Button'),
    Flex: Utils.loadComponent('proxy/Flex'),
  },
})
export default class Invoices extends Vue {
  @State((state) => state.wallet.invoices) public invoicesState!: any
  @State((state) => state.wallet.dateInvoices) public dateInvoices!: any
  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs

  @Action('getInvoices') public getInvoices: any
  @Action('setDateInvoices') public setDateInvoices: any
  @Action('payInvoice') public payInvoice: any

  // in case we'll need to select a wallet
  // @Prop({
  // }) protected wallets?: Array

  protected selectedDate?: string | null = null

  protected currentList?: Invoice[] = []

  protected delayId?: any = undefined

  protected month: string = moment().locale('en').format('YYYY-MM')

  protected listLoading: boolean = false
  protected paymentInProgress: boolean = false

  protected get monthList() {
    if (this.invoicesState) {
      if (this.currentList) {
        return this.currentList
      } else if (this.selectedDate && this.invoicesState.hasOwnProperty(this.selectedDate)) {
        return this.invoicesState[this.selectedDate]
      } else if (this.month && this.invoicesState.hasOwnProperty(this.month)) {
        return this.invoicesState[this.month]
      }
    }
  }

  protected get invoicesEmpty() {
    return !this.invoicesState || Object.entries(this.invoicesState).length === 0 && this.invoicesState.constructor === Object
  }

  protected get logo() {
    const fc = this.fieldConfigs as Record<string, any> | undefined
    return fc ? Utils.getProp(fc, ['invoices', 'paymentGatewayLogo']) : null
  }

  protected statusClass(invoice: Invoice) {
    return {
      paid: (invoice.status === 'PAID'),
      hold: (invoice.status === 'HOLDOVER PAYMENT' || invoice.status === 'SENT'),
    }
  }

  protected setDelay(month: string) {
    if (typeof this.delayId === 'number') {
      this.clearDelay()
    }
    const that = this
    this.delayId = setTimeout(() => {
      that.getInvoices(month)
    }, 500)
  }

  protected clearDelay() {
    clearTimeout(this.delayId)
    this.delayId = undefined
  }

  protected changedMonth(month: string) {
    this.listLoading = true
    this.currentList = []
    if (this.invoicesEmpty) {
      this.getInvoices(month).then(() => {
          this.currentList = this.invoicesState[month]
          this.setDateInvoices(month)
          this.listLoading = false
        },
      )
    } else {
      // if there is no data available
      if (this.invoicesState[month] === undefined) {
        // then get the list
        this.getInvoices(month).then(() => {
          this.currentList = this.invoicesState[month]
          this.setDateInvoices(month)
          this.listLoading = false
        })
        // if there is data available
      } else {
        // get list from local storage
        this.currentList = this.invoicesState[month]
        this.setDateInvoices(month)
        this.setDelay(month)
        this.listLoading = false
      }
    }
  }

  protected formatDate(date: string) {
    return moment(date).format('LL')
  }

  protected invoiceIsPayable(inv: Invoice) {
    const s = inv.status
    const type = inv.type
    return type === 'INVOICE' && s !== 'PAID' && s !== 'HOLDOVER PAYMENT' && s !== 'AWAITING PAYMENT' && s !== 'CANCELED'
  }

  protected mounted() {
    if (this.invoicesEmpty) {
      this.getInvoices(this.month)
    }
    if (!this.dateInvoices) {
      this.setDateInvoices(this.month)
      this.selectedDate = this.month
    } else {
      this.getInvoices(this.dateInvoices).then(() => {
          if (this.dateInvoices !== undefined) {
            this.currentList = this.invoicesState[this.dateInvoices]
          }
          this.listLoading = false
        },
      )
      this.selectedDate = this.dateInvoices
    }
  }

  protected payNow(id: string) {
    this.paymentInProgress = true
    this.payInvoice(id)
      .then(() => {
        this.listLoading = true
        this.getInvoices(this.month).then(() => {
            this.currentList = this.invoicesState[this.month]
            this.listLoading = false
          },
        )
        this.$dialog.open(ConfirmDialogCallback, {
          props: {
            imageState: 'icons/Card-big.svg',
            confirmText: this.$t('common.ok'),
            code: '',
            subtitle: this.$t('common.thank_you'),
            title: this.$t('invoices.confirm'),
            confirmCallback: () => {
              this.paymentInProgress = false
            },
          },
        })
      }).catch(() => {
      this.getInvoices(this.month)
      this.paymentInProgress = false
    }).finally(() => {
      this.$emit('updateWallets')
    })
    // in case we'll need to select a wallet
    // use @Getter('defaultWallet') public defaultWallet?: Wallet
    // const payload = {
    //   wallet_id: this.defaultWallet.id,
    //   payment_method_id: this.defaultWallet.payment_method.id
    // }
    // this.payInvoice(id, payload)
  }

  protected openDetail(invoice: any) {
    const title = invoice.item.type === 'INVOICE' ? `Invoice: ${invoice.item.number}` : invoice.item.type
    this.$popup.open(InvoiceDetail, {props: {invoice: invoice.item}, title})
  }

  protected openInvoice(invoiceItem: Invoice) {
    cordova.InAppBrowser.open(invoiceItem.url, '_system')
  }
}
